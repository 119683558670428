import { Continent, Country } from 'src/types/Countries'

export const selectAllCountries = (continents: Continent[]) =>
  continents.reduce<Country[]>(
    (result, continent) => [...result, ...continent.countries],
    [],
  )

export const selectPhoneNumberAllowedCountries = (
  continents: Continent[],
): Country[] =>
  selectAllCountries(continents).filter(country => country.phoneNumberAllowed)
