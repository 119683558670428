import { gql } from '@apollo/client'

export default gql`
  mutation portal_login(
    $phoneCountryCode: String!
    $phoneNumber: String!
    $permitPortalId: UUID!
    $verificationMethod: PermitPortalUserLoginInputVerificationMethod
  ) {
    portal_login(
      input: {
        phoneCountryCode: $phoneCountryCode
        phoneNumber: $phoneNumber
        verificationMethod: $verificationMethod
        permitPortalId: $permitPortalId
      }
    ) {
      success
    }
  }
`
