/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosRequestConfig } from 'axios'
import { isArray, isPlainObject } from 'lodash'

import Info from 'src/info'
import { CountriesResponse } from 'src/types/Countries'
import { generateAuthenticationHeaders } from 'src/utils/authentication'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,

  transformRequest: [
    (data: Record<string, any>, headers: Record<string, any>): any => {
      headers['Content-Type'] = 'application/json'
      headers['Citifyd-app-version'] = `Live ${Info.version}`
      headers['Citifyd-accept-language'] = 'en-us'

      if (!headers['Authorization']) {
        const authHeaders = generateAuthenticationHeaders()

        for (const header in authHeaders) {
          if (!(header in headers)) headers[header] = authHeaders[header]
        }
      }

      return isPlainObject(data) || isArray(data) ? JSON.stringify(data) : data
    },
  ],
})

// Countries
export const getCountries = (data?: AxiosRequestConfig) =>
  api.get<CountriesResponse>('countries', data).then(response => response.data)
