import Image from 'next/image'
import React, { useEffect } from 'react'
import { Col, Container, Row, Visible } from 'react-grid-system'

import Link from 'src/components/Link'
import Text from 'src/components/Text'

import styles from './Footer.module.scss'

interface FooterProps {
  light?: boolean
}

const Footer: React.FunctionComponent<FooterProps> = ({
  light,
}: FooterProps) => {
  useEffect(() => {
    document.body.classList.add(styles.body)
  }, [])

  return (
    <div className={styles.wrapper}>
      <Visible sm>
        <footer className={styles.footer}>
          <Container>
            <Contents light={light} />
          </Container>
        </footer>
      </Visible>

      <Visible lg md xl>
        <Container>
          <footer className={styles.footer}>
            <Contents light={light} />
          </footer>
        </Container>
      </Visible>
    </div>
  )
}

const Contents = ({ light }: { light: boolean }) => {
  return (
    <Row>
      <Col md={5} xl={8} xs={3}>
        <Image height={52} src="/static/images/logoFooter.svg" width={62} />
      </Col>

      <Col md={3} xl={2} xs={4}>
        <Text
          color={light ? 'white' : 'default'}
          lineHeight="tiny"
          size="small"
          weight="800"
        >
          Need help?
        </Text>

        <FooterLink href="tel:5036882111" light={light}>
          503-688-2111
        </FooterLink>
        <FooterLink href="mailto:support@citifyd.com" light={light}>
          support@citifyd.com
        </FooterLink>
      </Col>

      <Col md={3} xl={2} xs={5}>
        <Text
          color={light ? 'white' : 'default'}
          lineHeight="tiny"
          size="small"
          weight="800"
        >
          About Citifyd
        </Text>
        <FooterLink
          href="https://policies.citifyd.com/terms"
          light={light}
          target="_blank"
        >
          Terms and conditions
        </FooterLink>
        <FooterLink
          href="https://policies.citifyd.com/privacy"
          light={light}
          target="_blank"
        >
          Privacy Policy
        </FooterLink>
      </Col>
    </Row>
  )
}

const FooterLink = ({
  light,
  href,
  children,
  ...props
}: {
  children: React.ReactNode
  href: string
  light: boolean
  target?: string
}) => {
  return (
    <div>
      <Link
        {...props}
        color={light ? 'white' : 'default'}
        href={href}
        lineHeight="small"
        size="small"
        weight="600"
      >
        {children}
      </Link>
    </div>
  )
}

export default Footer
