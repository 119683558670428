import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

import LoadingOverlay from 'src/components/LoadingOverlay'
import LoginForm from 'src/components/Login/Form'
import Logo from 'src/components/Logo'
import Template from 'src/components/Template'
import PORTAL_LOGIN from 'src/graphql/PermitPortalUser/mutations/PortalLogin'
import PORTAL_VERIFY_LOGIN from 'src/graphql/PermitPortalUser/mutations/PortalVerifyLogin'
import { withAuthServerSideProps } from 'src/hoc/withAuth'
import useNotification from 'src/hooks/useNotification'
import {
  LoginFormikParams,
  LoginResponse,
  SendCodeParams,
  VerifyLoginResponse,
} from 'src/types/Auth'
import { PageProps } from 'src/types/Page'
import { authenticateUser } from 'src/utils/authentication'
import { getNationalPhoneNumber } from 'src/utils/phoneNumber'

import styles from './Login.module.scss'

type Steps = 1 | 2

type RequestCodeType = 'CALL' | 'CODE'

const Login = (props: PageProps) => {
  const { permitPortal } = props
  const router = useRouter()
  const [step, setStep] = useState<Steps>(1)
  const [loading, setLoading] = useState(false)
  const [requestNewCode, setRequestNewCode] = useState<RequestCodeType>()
  const isInitialStep = useMemo(() => step === 1, [step])
  const { showNotification } = useNotification()
  const { query } = router

  const [login, { data: dataLogin, error: errorLogin }] =
    useMutation<LoginResponse>(PORTAL_LOGIN)

  const [verifyLogin, { data: dataVerifyLogin, error: errorVerifyLogin }] =
    useMutation<VerifyLoginResponse>(PORTAL_VERIFY_LOGIN)

  useEffect(() => {
    if (dataLogin) {
      setStep(2)
      setLoading(false)

      if (requestNewCode) {
        showNotification.success({
          message:
            requestNewCode === 'CODE'
              ? 'New code sent successfully.'
              : 'You will receive a call with a new code soon.',
        })
      }

      setRequestNewCode(null)
    }
  }, [dataLogin, requestNewCode])

  useEffect(() => {
    if (errorLogin) {
      setLoading(false)

      if (requestNewCode) {
        showNotification.error({
          message:
            requestNewCode === 'CODE'
              ? 'We are unable to send a new code, please try again.'
              : 'We are unable to call, please try again.',
        })
      }

      setRequestNewCode(null)

      showNotification.error({ message: errorLogin.message })
    }
  }, [errorLogin, requestNewCode])

  useEffect(() => {
    if (errorVerifyLogin) {
      setLoading(false)
      showNotification.error({ message: errorVerifyLogin.message })
      setRequestNewCode(null)
    }
  }, [errorVerifyLogin])

  useEffect(() => {
    const onUserAuthentication = async () => {
      const { source } = query

      const accessToken =
        dataVerifyLogin.portal_verifyLogin?.activeSession?.accessToken

      if (accessToken) {
        authenticateUser(
          dataVerifyLogin.portal_verifyLogin?.activeSession?.accessToken,
        )

        await router.push(
          source ? decodeURIComponent(source.toString()) : '/calendar',
        )
        setLoading(false)
      } else {
        setLoading(false)
        showNotification.error({
          message: 'We are unable to authenticate this user, please try again.',
        })
      }
    }

    if (dataVerifyLogin) {
      void onUserAuthentication()
    }
  }, [dataVerifyLogin, query, router])

  const onSendCodeAgain = async ({
    phoneCountryCode,
    phoneNumber,
  }: SendCodeParams) => {
    if (loading) {
      return null
    }

    setLoading(true)
    setRequestNewCode('CODE')

    await login({
      variables: {
        permitPortalId: permitPortal.id,
        phoneCountryCode: phoneCountryCode.toLowerCase(),
        phoneNumber: getNationalPhoneNumber(phoneNumber, phoneCountryCode),
      },
    })
  }

  const onCallMe = async ({
    phoneCountryCode,
    phoneNumber,
  }: SendCodeParams) => {
    if (loading) {
      return null
    }

    setLoading(true)
    setRequestNewCode('CALL')

    await login({
      variables: {
        permitPortalId: permitPortal.id,
        phoneCountryCode: phoneCountryCode.toLowerCase(),
        phoneNumber: getNationalPhoneNumber(phoneNumber, phoneCountryCode),
        verificationMethod: 'CALL',
      },
    })
  }

  const onSubmit = async (values: LoginFormikParams) => {
    setLoading(true)

    if (isInitialStep) {
      await login({
        variables: {
          permitPortalId: permitPortal.id,
          phoneCountryCode: values?.phoneCountryCode.toLowerCase(),
          phoneNumber: getNationalPhoneNumber(
            values?.phoneNumber,
            values?.phoneCountryCode,
          ),
        },
      })
    } else {
      await verifyLogin({
        variables: {
          code: values?.code,
          permitPortalId: permitPortal.id,
          phoneCountryCode: values?.phoneCountryCode.toLowerCase(),
          phoneNumber: getNationalPhoneNumber(
            values?.phoneNumber,
            values?.phoneCountryCode,
          ),
        },
      })
    }
  }

  return (
    <Template
      {...props}
      backState={!isInitialStep ? () => setStep(1) : null}
      width={{ cols: 4 }}
    >
      <div className={styles.login}>
        <div className={styles.logoContainer}>
          <Logo image={permitPortal.logo} />
        </div>

        <LoadingOverlay isLoading={loading}>
          <LoginForm
            isInitialStep={isInitialStep}
            onCallMe={onCallMe}
            onSendCodeAgain={onSendCodeAgain}
            onSubmit={onSubmit}
          />
        </LoadingOverlay>
      </div>
    </Template>
  )
}

export const getServerSideProps = withAuthServerSideProps()

export default Login
