/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import { includes } from 'lodash'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as Yup from 'yup'

export const formatPhoneNumber = (phoneNumber: string, code?: CountryCode) => {
  const countryCode = code?.toUpperCase() as CountryCode

  if (!phoneNumber) {
    return ''
  }

  let formattedPhoneNumber: string

  if (!countryCode || includes(['US', 'CA'], countryCode)) {
    const number = parsePhoneNumberFromString(phoneNumber, countryCode || 'US')

    formattedPhoneNumber = number.formatNational()
  } else {
    const number = parsePhoneNumberFromString(phoneNumber, countryCode)

    formattedPhoneNumber = number ? number.formatInternational() : ''
  }

  return formattedPhoneNumber
}

export const phoneNumberSchema = () =>
  Yup.string().test({
    name: 'phoneNumber',
    message: 'Invalid phone number',
    test: value => {
      return value?.length > 3 && isValidPhoneNumber(value)
    },
  })

export const getNationalPhoneNumber = (phoneNumber: string, countryCode) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(phoneNumber, countryCode)

  return number ? number.nationalNumber : ''
}

export const getPhoneNumberObject = (phoneNumber: string) => {
  return phoneNumber && parsePhoneNumberFromString(phoneNumber)
}

export const getInternationalPhoneNumber = (
  phoneNumber: string,
  countryCode,
) => {
  if (!phoneNumber || !countryCode) {
    return ''
  }

  const number = parsePhoneNumberFromString(phoneNumber, countryCode)

  return number ? number.number : ''
}
