import classNames from 'classnames'
import React from 'react'

import styles from './Button.module.scss'

interface ButtonProps {
  align?: 'left' | 'center' | 'right'
  appearance?: 'primary' | 'secondary' | 'tertiary' | 'secondaryOutline'
  as?: typeof React.Component | string
  children: React.ReactNode
  className?: string
  desktopSize?: 'default' | 'small'
  disabled?: boolean
  fullWidth?: boolean
  gutterBottom?: boolean
  href?: string
  onClick?: React.MouseEventHandler<HTMLElement>
  size?: 'default' | 'small'
  target?: string
  type?: string
  uppercase?: boolean
}

const Button: React.FunctionComponent<ButtonProps> = ({
  align = 'center',
  as: Component = 'button',
  appearance,
  children,
  className,
  disabled,
  fullWidth,
  gutterBottom,
  href,
  onClick,
  target,
  uppercase = true,
  size,
  desktopSize,
  ...props
}: ButtonProps) => {
  return (
    <Component
      className={classNames(
        styles.button,
        className,
        appearance ? styles[appearance] : styles.primary,
        {
          [styles[size]]: size,
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
          [styles.gutterBottom]: gutterBottom,
          [styles['desktopSize-' + desktopSize]]: desktopSize,
          [styles.uppercase]: uppercase,
          [styles['text-' + align]]: align,
        },
      )}
      disabled={disabled}
      href={href}
      onClick={onClick}
      target={target}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button
