import Head from 'next/head'
import Image from 'next/image'
import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-grid-system'

import Text from 'src/components/Text'

import Footer from '../Footer'

import styles from './Template.module.scss'

type Props = {
  backState?: () => void | string
  children?: ReactNode
  lightFooter?: boolean
  title?: string
  width?: { cols: number }
}

const Template = ({
  children,
  backState,
  width = { cols: 12 },
  title = 'Booking Portal',
  lightFooter,
}: Props) => {
  const offset = (12 - width.cols) / 2

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta content="initial-scale=1.0, width=device-width" name="viewport" />
      </Head>
      <Container>
        <div className={styles.template}>
          {backState && (
            <button
              className={styles.backButton}
              onClick={backState}
              type="button"
            >
              <Image height={12} src="/static/icons/arrow_left.svg" width={8} />{' '}
              <Text className={styles.backText}>Back</Text>
            </button>
          )}
          <div className={styles.content}>
            <Row>
              <Col lg={width.cols} offset={{ lg: offset }}>
                {children}
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Footer light={lightFooter} />
    </>
  )
}

export default Template
