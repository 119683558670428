import classNames from 'classnames'

import styles from './Loading.module.scss'

const Loading = ({ white }: { white?: boolean }) => {
  return (
    <div className={styles.componentLoadingIndicator}>
      <div className={styles.cssloadContainer}>
        <div
          className={classNames(styles.cssloadSpeedingWheel, {
            [styles.white]: white,
          })}
        />
      </div>
    </div>
  )
}

export default Loading
