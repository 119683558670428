import { gql } from '@apollo/client'

import PermitPortalUser from '../fragments/PermitPortalUserFragment'

export default gql`
  mutation portal_verifyLogin(
    $code: String!
    $phoneCountryCode: String!
    $phoneNumber: String!
    $permitPortalId: UUID!
  ) {
    portal_verifyLogin(
      input: {
        code: $code
        phoneCountryCode: $phoneCountryCode
        phoneNumber: $phoneNumber
        permitPortalId: $permitPortalId
      }
    ) {
      ...PermitPortalUser
    }
  }
  ${PermitPortalUser}
`
