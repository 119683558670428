import classNames from 'classnames'
import { FunctionComponent, ReactNode } from 'react'

import Loading from '../Loading'

import styles from './LoadingOverlay.module.scss'

interface LoadingOverlayProps {
  children: ReactNode
  className?: string
  isLoading: boolean
}

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({
  children,
  isLoading,
  className,
}: LoadingOverlayProps) => {
  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={classNames([styles.loadingIndicator, className])}>
            <Loading />
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default LoadingOverlay
