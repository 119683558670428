import classNames from 'classnames'
import React from 'react'

import { TextFieldProps } from 'src/types/Form'

import styles from './TextField.module.scss'

const TextField: React.FunctionComponent<TextFieldProps> = ({
  renderInput,
  label,
  fieldId,
  className,
  reducedPadding,
  inputBlock,
  gutterBottom = true,
  error,
}: TextFieldProps) => {
  const hasError = typeof error === 'string'

  return (
    <div
      className={classNames(styles.textField, className, {
        [styles.gutterBottom]: gutterBottom,
      })}
    >
      {label && (
        <div
          className={classNames(styles.labelBox, { [styles.error]: hasError })}
        >
          <label htmlFor={fieldId}>{label}</label>
        </div>
      )}
      <div
        className={classNames(styles.inputBox, {
          [styles.error]: hasError,
          [styles.reducedPadding]: reducedPadding,
          [styles.inputBlock]: inputBlock,
        })}
      >
        {renderInput}
      </div>

      {hasError && <p className={styles.errorMessage}>{error}</p>}
    </div>
  )
}

export default TextField
