import classNames from 'classnames'
import React from 'react'

import Text from 'src/components/Text'

import styles from './Logo.module.scss'

interface LogoProps {
  image?: string
  size?: 'small' | 'base'
}

const Logo: React.FunctionComponent<LogoProps> = ({
  image,
  size = 'base',
}: LogoProps) => {
  return (
    <div className={classNames(styles.logo, styles['size-' + size])}>
      {image ? (
        <div
          className={styles.image}
          style={{ backgroundImage: image && `url(${image})` }}
        />
      ) : (
        <Text
          color="gray"
          desktopSize={size === 'base' ? 'large2x' : 'medium'}
          size={size === 'base' ? 'large2x' : 'small'}
          textAlign="center"
          textTransform="uppercase"
        >
          Logo
        </Text>
      )}
    </div>
  )
}

export default Logo
