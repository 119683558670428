import classNames from 'classnames'
import { useFormikContext } from 'formik'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'

import { getCountries } from 'src/api'
import FormTextField from 'src/components/Form/FormTextField'
import { CountryProps } from 'src/types/Form'
import { selectAllCountries } from 'src/utils/countries'

import styles from './PhoneNumberField.module.scss'

type Countries = { name?: string; value?: string }

type PhoneNumberProps = {
  gutterBottom?: boolean
  phoneCountryCode?: string
}

const PhoneNumberField = ({
  field,
  selectedCountry,
  disabled,
  className,
  gutterBottom = true,
  ...rest
}: CountryProps) => {
  const [countries, setCountries] = useState<Countries[]>([])
  const { setFieldValue, values } = useFormikContext<PhoneNumberProps>()
  const { phoneCountryCode } = values

  useEffect(() => {
    const fetchData = () => {
      void getCountries().then(response => {
        const countries = selectAllCountries(response.continents)

        setCountries(
          countries
            .map(country => ({
              name: country.name,
              value: country.isoCode,
            }))
            .sort((a, b) => a?.name?.localeCompare(b?.name)),
        )
      })
    }

    void fetchData()
  }, [])

  return (
    <div
      className={classNames(styles.phoneNumberField, className, {
        [styles.gutterBottom]: gutterBottom,
      })}
    >
      <FormTextField
        className={styles.textField}
        component={PhoneInput}
        country={phoneCountryCode?.toUpperCase()}
        disabled={disabled}
        field={field}
        gutterBottom={gutterBottom}
        reducedPadding
        {...rest}
      >
        <div className={styles.countryBox}>
          {phoneCountryCode && (
            <div
              className={classNames(styles.flag, {
                [styles.disabled]: disabled,
              })}
            >
              <Image
                height={32}
                src={`/static/icons/country-flags/${phoneCountryCode?.toLowerCase()}@2x.png`}
                width={42}
              />
            </div>
          )}
          <select
            className={styles.select}
            disabled={disabled}
            onChange={e => setFieldValue('phoneCountryCode', e.target?.value)}
            value={phoneCountryCode?.toUpperCase()}
          >
            {countries.map(item => (
              <option key={item.name} value={item.value?.toUpperCase()}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </FormTextField>
    </div>
  )
}

export default PhoneNumberField
