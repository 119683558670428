import React, { useMemo } from 'react'

import TextField from 'src/components/TextField'
import { FormTextFieldProps } from 'src/types/Form'

const FormTextField: React.FunctionComponent<FormTextFieldProps> = ({
  field,
  form: { touched, errors: formikErrors, setFieldValue, setFieldTouched },
  error: manualError,
  requireTouchForError = true,
  className,
  children,
  reducedPadding,
  component: Component,
  options,
  gutterBottom,
  onChange,
  placeholder,
  ...props
}: FormTextFieldProps) => {
  const { label, onKeyUp } = props

  const error = useMemo(
    () =>
      (touched[field.name] || !requireTouchForError) &&
      (manualError ?? (formikErrors[field.name] as string)),
    [field.name, touched, formikErrors, manualError],
  )

  const onValueChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }

    setFieldValue(field.name, value)
  }

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()

    if (onChange) {
      onChange(event.target.value)
    }

    setFieldValue(field.name, event.target.value)
  }

  const onBlur = () => {
    setFieldTouched(field.name, true)
  }

  return (
    <TextField
      className={className}
      error={error}
      gutterBottom={gutterBottom}
      label={label}
      reducedPadding={reducedPadding}
      renderInput={
        <>
          {children}
          {Component && (
            <Component
              {...props}
              {...field}
              name={field.name}
              onBlur={onBlur}
              onChange={onValueChange}
              onKeyUp={e => {
                onKeyUp && onKeyUp(e)
              }}
            />
          )}
          {!Component && options && (
            <select {...props} {...field} onChange={onSelectChange}>
              {placeholder && <option value="">{placeholder}</option>}
              {options?.map(option => (
                <option
                  disabled={option.disabled}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          )}
          {!Component && !options && (
            <input
              {...props}
              {...field}
              onKeyUp={e => {
                onKeyUp && onKeyUp(e)
              }}
              placeholder={placeholder}
            />
          )}
        </>
      }
    />
  )
}

export default FormTextField
