import { Field, Form, Formik } from 'formik'
import { CountryCode } from 'libphonenumber-js'
import React from 'react'
import * as Yup from 'yup'

import Button from 'src/components/Button'
import FormTextField from 'src/components/Form/FormTextField'
import PhoneNumberField from 'src/components/Form/PhoneNumberField'
import Link from 'src/components/Link'
import Text from 'src/components/Text'
import { LoginFormParams } from 'src/types/Auth'
import { formatPhoneNumber, phoneNumberSchema } from 'src/utils/phoneNumber'

import styles from './Form.module.scss'

const LoginForm = ({
  onSubmit,
  onCallMe,
  onSendCodeAgain,
  isInitialStep,
}: LoginFormParams) => {
  return (
    <Formik
      initialValues={{
        phoneCountryCode: 'US',
        phoneNumber: '',
        code: '',
      }}
      onSubmit={async values => onSubmit(values)}
      validationSchema={Yup.object({
        phoneNumber: phoneNumberSchema(),
        ...(!isInitialStep && { code: Yup.string().required() }),
      })}
    >
      {({ values }) => (
        <Form className={styles.form}>
          {isInitialStep && (
            <>
              <Text gutterBottom="base" lineHeight="large" size="large">
                Please log in using your phone number to issue parking permits
              </Text>
              <Field
                component={PhoneNumberField}
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone number"
              />
            </>
          )}
          {!isInitialStep && (
            <>
              <Text gutterBottom="base" lineHeight="large" size="large">
                Enter the security code sent by SMS to
                <br />
                {formatPhoneNumber(
                  values.phoneNumber,
                  values.phoneCountryCode as CountryCode,
                )}
              </Text>
              <Field
                component={FormTextField}
                id="code"
                name="code"
                placeholder="Security code"
              />
            </>
          )}

          <Button appearance="primary" gutterBottom type="submit">
            Log in
          </Button>

          {!isInitialStep && (
            <div className={styles.codeLinks}>
              <Link
                className={styles.codeLink}
                gutterBottom="base"
                lineHeight="large"
                onClick={() => void onSendCodeAgain({ ...values })}
                size="large"
              >
                Send code again
              </Link>

              <Link
                className={styles.codeLink}
                gutterBottom="base"
                lineHeight="large"
                onClick={() => void onCallMe({ ...values })}
                size="large"
              >
                Call my phone with the code
              </Link>
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
