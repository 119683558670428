import { useContext } from 'react'

import { NotificationContext } from 'src/context/Notifications'

const useNotification = () => {
  const { showNotification } = useContext(NotificationContext)

  return { showNotification }
}

export default useNotification
