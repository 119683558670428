import classNames from 'classnames'
import NextLink from 'next/link'
import React from 'react'

import Text, { TextProps } from 'src/components/Text'

import styles from './Link.module.scss'

interface LinkProps extends TextProps {
  children: React.ReactNode
  href?: string | object
  onClick?: () => void
  target?: string
}

const Link: React.FunctionComponent<LinkProps> = ({
  children,
  href,
  onClick,
  className,
  ...props
}: LinkProps) => {
  return onClick ? (
    <Text
      className={classNames(styles.link, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </Text>
  ) : (
    <NextLink href={href} passHref>
      <Text as="a" className={classNames(styles.link, className)} {...props}>
        {children}
      </Text>
    </NextLink>
  )
}

export default Link
